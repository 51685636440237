<template>
  <div>
    <br />
    <br />
    <br />
    <br />
    <br />
    {{ url }}
    <br>
    <button @click="reg">授权</button>
    <br />
    <br />
    <br />
    <br />
    <br />
    <!--<div class="preview-wrap">
      <img src="/assets/images/Apple.png" alt="" class="preview-bg">
      <div class="preview-content">
        <preview-template />
      </div>
    </div>-->
  </div>
</template>

<script>
import PreviewTemplate from './components/PreviewTemplate.vue'

export default {
  name: 'App',
  components: {
    PreviewTemplate,
    url: ''
  },
  created() {
    this.url = window.location.href
  },
  methods: {
    reg() {
      // const redirect_uri = encodeURIComponent('http://qxu1591210146.my3w.com')
      const redirect_uri = encodeURIComponent('https://2a9f-2408-8210-3c45-f4d1-c5c2-cdfc-1137-e78e.ngrok.io/')
      // const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7db24d0b02e43766&redirect_uri=https%3A%2F%2F2a9f-2408-8210-3c45-f4d1-c5c2-cdfc-1137-e78e.ngrok.io%2F&response_type=code&scope=snsapi_base#wechat_redirect`
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4a1f322051cec7e7&redirect_uri=http%3A%2F%2Fwx.zhurunlin.com%2F&response_type=code&scope=snsapi_base#wechat_redirect`
      console.log(url)
      window.location.replace(url)
    }
  }
}
</script>

<style>
.preview-wrap {
  display: inline-block;
  position: relative;
}

.preview-bg {
  position: relative;
  width: 411px;
  max-width: 100%;
  z-index: 3;
  pointer-events: none;
}

.preview-content {
  position: absolute;
  top: 17px;
  bottom: 21px;
  left: 18px;
  right: 18px;
  /*border: 5px solid red;*/
  border-radius: 45px;
  overflow: hidden;
}
</style>
