<template>
  <div class="template-wrap">
    <template v-if="template">
      <div class="block-top" hidden1>
        <div class="block-top-poster">
          <template v-if="template.topType === 1 && template.topPicUrl">
            <video :src="template.topMediaUrl" :poster="template.topPicUrl" controls controlslist="nodownload nofullscreen noremoteplayback" />
          </template>
          <template v-if="template.topType === 2 && template.topPicUrl">
            <img :src="template.topPicUrl" alt="">
          </template>
        </div>
        <div class="block-top-content">
          <div class="block-top-title">
            <p>{{ template.topFirTitle || '' }}</p>
            <p>{{ template.topSecTitle || '' }}</p>
          </div>
          <div class="block-top-desc">
            <p>{{ template.topFirContent || '' }}</p>
            <p>{{ template.topSecContent || '' }}</p>
          </div>
          <div class="block-top-info">
            <span>{{ template.createDate || '' }}</span>
            <span class="block-top-line"></span>
            <span>{{ template.browseCount || '0' }}阅读</span>
          </div>
          <div class="block-top-footer">
            <div class="scroll-down">
              <div class="scroll-wrap">
                <div class="scroll-bar"></div>
              </div>
            </div>
            <span class="scroll-down-text">向下浏览</span>
            <div class="share-wrap">
              <img src="/assets/images/share.png" alt="" @click="preview">
            </div>
          </div>
        </div>
      </div>
      <!--模板-->
      <template v-if="template.contentType === 1 && template.templateList.length > 0">
        <div v-for="item in template.templateList" :key="item.id" class="block-container" :class="'block-'+item.templateType">
          <template v-if="item.templateType === 1">
            <div class="block-1-bg"></div>
            <div class="block-1-template">
              <img :src="item.titlePicUrl" alt="" class="block-1-pic">
              <div class="block-1-content">
                <div class="block-template-number">01</div>
                <div class="block-template-title">
                  <p>{{ item.firTitle || '' }}</p>
                  <p>{{ item.secTitle || '' }}</p>
                </div>
                <div class="block-template-subtitle">
                  <p>{{ item.firSubtitle || '' }}</p>
                  <p>{{ item.secSubtitle || '' }}</p>
                </div>
              </div>
            </div>
            <div class="block-rich-text" v-html="item.content"></div>
          </template>
          <template v-if="item.templateType === 2">
            <div class="block-2-bg"></div>
            <div class="block-2-template">
              <img :src="item.titlePicUrl" alt="" class="block-2-pic">
              <div class="block-2-content">
                <div class="block-template-number">02</div>
                <div class="block-template-title">
                  <p>{{ item.firTitle || '' }}</p>
                  <p>{{ item.secTitle || '' }}</p>
                </div>
              </div>
              <div class="block-rich-text" v-html="item.content"></div>
            </div>
          </template>
          <template v-if="item.templateType === 3">
            <div class="block-3-template">
              <img :src="item.titlePicUrl" alt="" class="block-2-pic">
              <div class="block-3-content">
                <div class="block-3-title-wrap">
                  <div class="block-template-number">03</div>
                  <div class="block-template-title">
                    <p>{{ item.firTitle || '' }}</p>
                    <p>{{ item.secTitle || '' }}</p>
                  </div>
                </div>
                <div class="block-template-subtitle">
                  <p>{{ item.firSubtitle || '' }}</p>
                  <p>{{ item.secSubtitle || '' }}</p>
                </div>
              </div>
              <div class="block-3-panel">
                <div class="block-3-bg"></div>
                <div class="block-3-panel-pic">
                  <img :src="item.firContentUrl" alt="">
                </div>
                <div class="block-3-panel-text">{{ item.firContent || '' }}</div>
              </div>
              <div class="block-3-panel">
                <div class="block-3-bg-1"></div>
                <div class="block-3-panel-text">{{ item.secContent || '' }}</div>
                <div class="block-3-panel-pic">
                  <img :src="item.secContentUrl" alt="">
                </div>
              </div>
            </div>
            <div class="block-rich-text" v-html="item.content"></div>
          </template>
        </div>
      </template>
      <!--富文本-->
      <template v-if="template.content">
        <div class="rich-text" v-html="template.content"></div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "previewtemplate",

  data() {
    return {
      template: null
    }
  },

  created() {
    this.template = {
      "toptype": 1,
      "classcode": "pet",
      "toppicurl": "https://canonpoints-test-public.oss-cn-beijing.aliyuncs.com/sign-manager/2022-03-22/1506160460137758720___materials_top_pic_r5世界大战.gif",
      "author": null,
      "browsecount": null,
      "templatelist": [
        {
          "templatetype": 1,
          "fircontent": "",
          "firsubtitle": "娃娃",
          "seccontent": "",
          "orderby": 0,
          "titlepicurl": "https://canonpoints-test-public.oss-cn-beijing.aliyuncs.com/sign-manager/2022-03-22/1506160460137758721___materials_template_pic_r5世界大战.jpg",
          "content": "<p>娃娃水水</p>",
          "firtitle": "日本",
          "rmaid": 521,
          "sectitle": "试试",
          "fircontenturl": null,
          "id": 118,
          "seccontenturl": null,
          "secsubtitle": "这种"
        },
        {
          "templatetype": 2,
          "fircontent": "",
          "firsubtitle": "",
          "seccontent": "",
          "orderby": 1,
          "titlepicurl": "https://canonpoints-test-public.oss-cn-beijing.aliyuncs.com/sign-manager/2022-03-22/1506160460137758722___materials_template_pic_r5世界大战.jpg",
          "content": "<p>hello12323</p>",
          "firtitle": "血腥",
          "rmaid": 521,
          "sectitle": "微信",
          "fircontenturl": null,
          "id": 131,
          "seccontenturl": null,
          "secsubtitle": ""
        },
        {
          "templatetype": 3,
          "fircontent": "威威大多数",
          "firsubtitle": "威威大多数",
          "seccontent": "许许多多的是",
          "orderby": 2,
          "titlepicurl": null,
          "content": "<p>hello身上的味道</p>",
          "firtitle": "威威大多数",
          "rmaid": 521,
          "sectitle": "威威大多数",
          "fircontenturl": "https://canonpoints-test-public.oss-cn-beijing.aliyuncs.com/sign-manager/2022-03-22/1506160460137758723___materials_template_fir_content_pic_r5世界大战.jpg",
          "id": 144,
          "seccontenturl": "https://canonpoints-test-public.oss-cn-beijing.aliyuncs.com/sign-manager/2022-03-22/1506160460137758724___materials_template_sec_content_pic_r5世界大战.jpg",
          "secsubtitle": "威威大多数"
        }
      ],
      "likcount": null,
      "title": "r5世界大战",
      "content": "",
      "topfircontent": "威威",
      "picurl": "https://canonpoints-test-public.oss-cn-beijing.aliyuncs.com/sign-manager/2022-03-18/1504767580777218048___materials_pic_r5大战.jpg",
      "sharecount": null,
      "labellist": [
        {
          "label": "女",
          "value": "usersex02"
        },
        {
          "label": "男",
          "value": "usersex01"
        }
      ],
      "topmediaurl": "https://klxxcdn.oss-cn-hangzhou.aliyuncs.com/histudy/hrm/media/bg3.mp4",
      "topseccontent": "试试",
      "createuser": "sys",
      "id": 521,
      "contenttype": 1,
      "topfirtitle": "佳能",
      "topsectitle": "盛怒",
      "createdate": "2022-03-18 18:32:04",
      "status": 1
    }
    this.template1 = {
      "toptype": 2,
      "classcode": "portrait",
      "toppicurl": "https://canonpoints-test-public.oss-cn-beijing.aliyuncs.com/sign-manager/2022-03-21/1505831852038946819___materials_top_pic_r5佳能摄影大赛.jpg",
      "author": null,
      "browsecount": null,
      "likcount": null,
      "title": "r5佳能摄影大赛",
      "content": "<h1><span style=\"color: rgb(225, 60, 57); font-family: 楷体;\"><em>袁晓汇</em></span></h1><p><img src=\"https://canonpoints-test-public.oss-cn-beijing.aliyuncs.com/sign-manager/2022-03-22/1506197802550558720___aapvqdv.jpg\" alt=\"\" data-href=\"\" style=\"width: 131.00px;height: 65.06px;\"/></p>",
      "topfircontent": "蓝色",
      "picurl": "https://canonpoints-test-public.oss-cn-beijing.aliyuncs.com/sign-manager/2022-03-21/1505831852038946818___materials_pic_r5佳能摄影大赛.jpg",
      "sharecount": null,
      "labellist": [
        {
          "label": "女",
          "value": "usersex02"
        },
        {
          "label": "10-19岁",
          "value": "userage02"
        },
        {
          "label": "大影家",
          "value": "login12month09"
        },
        {
          "label": "佳画廊",
          "value": "login12month10"
        },
        {
          "label": "否",
          "value": "equipmentis02"
        }
      ],
      "topmediaurl": null,
      "topseccontent": "妹妹",
      "createuser": "sys",
      "id": 534,
      "contenttype": 2,
      "topfirtitle": "摄影大赛",
      "topsectitle": "日本",
      "createdate": "2022-03-21 17:01:22",
      "status": 1
    }
  }
}
</script>

<style scoped>
img, video {
  width: 100%;
  display: block;
}

p {
  margin: 0;
}

.template-wrap {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding-bottom: 107px;
  color: rgba(255, 255, 255, 0.5);
  background-color: #180E26;
  overflow-y: auto;
  box-sizing: border-box;
}

.block-top-content {
  padding: 30px;
}

.block-top-title {
  font-size: 27px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 38px;
}

.block-top-desc {
  margin-top: 35px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 27px;
  opacity: 0.5;
}

.block-top-info {
  display: flex;
  align-items: center;
  gap: 18px;
  font-size: 14px;
  font-weight: 400;
  margin-top: 37px;
}

.block-top-info span {
  color: #FFFFFF;
  line-height: 36px;
  opacity: 0.22;
}

.block-top-info .block-top-line {
  display: inline-block;
  width: 1px;
  height: 1em;
  background-color: #FFFFFF;
  opacity: 0.45;
}

.block-top-footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 64px;
}

.block-top-footer .scroll-down-text {
  flex-grow: 1;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  opacity: 0.56;
  margin: 0 19px;
}

.block-top-footer .scroll-down {
  position: relative;
  display: inline-block;
  width: 1px;
  height: 44px;
  background: rgba(255, 255, 255, 0.2);
}

.block-top-footer .scroll-bar {
  position: absolute;
  bottom: 0;
  display: inline-block;
  width: 1px;
  height: 23px;
  background: #FFFFFF;
}

.block-top-footer .share-wrap {
  width: 22px;
}

.block-container {
  position: relative;
}

.block-template-number {
  font-size: 48px;
  /*font-family: DIN;*/
  font-weight: 400;
  color: #FFFFFF;
  line-height: 36px;
}

.block-template-title {
  margin-top: 28px;
  font-size: 19px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 36px;
}

.block-template-subtitle {
  margin-top: 17px;
  font-size: 13px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 21px;
  opacity: 0.5;
}

.block-rich-text {
  margin-top: 28px;
}

.block-1 {
  padding: 107px 30px 107px 25px;
}

.block-1-template {
  position: relative;
}

.block-1-bg {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  height: 200px;
  background: linear-gradient(90deg, #241a31 0%, #180e26 100%);
  z-index: 0;
}

.block-1-pic {
  position: absolute;
  top: -70px;
  right: 0;
  width: 218px;
}

.block-1-content {
  padding-left: 21px;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.block-2-template {
  position: relative;
  padding: 30px 30px 107px;
}

.block-2-bg {
  position: absolute;
  top: 75px;
  right: 0;
  width: 304px;
  height: 391px;
  background: linear-gradient(180deg, #241a31 0%, #180e26 100%);
  z-index: 0;
}

.block-2-pic {
  width: 249px;
}

.block-2-content {
  margin-top: 17px;
  padding-right: 19px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  text-align: right;
}

.block-3-template {
  position: relative;
  padding: 30px 30px 107px;
}

.block-3-content {
  padding-left: 21px;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.block-3-title-wrap {
  display: flex;
}

.block-3-title-wrap .block-template-number {
  line-height: 1em;
  margin-right: 19px;
}

.block-3-title-wrap .block-template-title {
  margin-top: 0;
}

.block-3-panel {
  position: relative;
  display: flex;
  align-items: center;
  padding: 30px 0;
}

.block-3-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -30px;
  width: 200px;
  background: linear-gradient(180deg, #241a31 0%, #180e26 100%);
  z-index: 0;
}

.block-3-bg-1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -30px;
  width: 200px;
  background: linear-gradient(180deg, #241a31 0%, #180e26 100%);
  z-index: 0;
}

.block-3-panel:not(:first-child) {
  margin-top: 24px;
}

.block-3-panel-pic {
  position: relative;
  flex-basis: 205px;
}

.block-3-panel-text {
  position: relative;
  flex-grow: 1;
}

.block-rich-text {
  padding: 20px;
}
</style>
